<template>
  <div>
    <div class="vx-row">
      <div class="vx-col w-full md:w-1/2 lg:w-3/4">
        <h2 class="app-heading mt-2">Widgets - {{ activeAppName }} <a href="https://support.travelgenix.io/hc/en-gb/categories/360003650057--Widgets" target="_blank"><feather-icon title="Support" icon="LifeBuoy" svgClasses="h-5 w-5 mr-4 text-primary" /></a></h2>
      </div>
    </div>
    <div class="vx-row mt-2 match-height">
      <div v-for="(widget, index) in widgetList" 
          :key="index" class="vx-col w-full md:w-1/2 lg:w-1/4 mt-4">
        <application-widget-card 
          :applicationId="applicationId" 
          :widget="widget"
          :showCounter="true"
          :showDescription="false" />
      </div>
    </div>
  </div>
</template>

<script>

import applicationWidgetStore from './applicationWidgetStore.js'
import ApplicationWidgetCard from './ApplicationWidgetCard.vue'

export default {
  props: {
    applicationId: { required: true }
  },
  components: {
    ApplicationWidgetCard
  },
  data () {
    return {
      searchText: null,
      resolvingAppId: false
    }
  },
  computed: {
    activeAppName () {
      return this.$store.state.ActiveApplicationName
    },
    widgetList () {
      return applicationWidgetStore.state.createdWidgets
    }
  },
  mounted () {
    //Component is loaded via url from address bar, applicationId prop will conflict with ApplicationId in $store, so update store
    if(this.applicationId != this.$store.state.ActiveApplicationId) {
      const activeApp = this.$_.find(this.$store.state.Applications, app => app.id == this.applicationId)
      this.resolvingAppId = true

      //Check if user has acccess to the app
      const activeUser = this.$store.state.AppActiveUser;
      if(activeUser.applications && activeUser.applications.find(x => x.id == this.applicationId)) {
        // This commit prevents redirect to home page by the ActiveApplicationId watcher
        this.$store.commit('UPDATE_SKIP_REDIRECT_AFTER_APP_SWITCH', true)
        this.$store.commit('UPDATE_ACTIVE_APP', { 
          id: parseInt(this.applicationId),
          name: activeApp.name,
          features: activeApp.features || [],
          appStatsUrl: activeApp.appStatsUrl,
          appStatsUrlLink: activeApp.appStatsUrlLink,
        })
      } else {
        this.$router.push('/error-404')
      }
      
    }
    this.$vs.loading()
    this.$store.dispatch('applicationWidgetStore/fetchCreatedWidgetsByApp', { applicationId: this.applicationId })
    .then(response => {
        
    })
    .catch(error => console.error(error))
    .finally(() => this.$vs.loading.close())
  },
  methods: {
    dummyMethod() {
    }
  }
}
</script>

<style scoped lang="scss">
.app-heading {
  color: #636363
}
</style>
